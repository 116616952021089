.mainContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 55px;
}

.mainContent h2 {
  font-size: 37px;
  text-align: end;
  color: #18457d;
  font-weight: bold;
  padding-right: 25%;
  padding-bottom: 3%;
}

.secondDiv {
  display: flex;
  justify-content: center;
  padding: 15px 40px 15px 40px;
  width: 670px;
  font-size: 38px;
  font-weight: bold;
  color: #18457d;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  margin-bottom: 63px;
  background-color: #efeded;
  border-radius: 20px;
  /* height: 500px; */
  text-align: left;
}

.firstImage {
  height: 350px;
}

.appointment {
  display: flex;
  justify-content: center;
  padding: 90px;
  font-size: 45px;
  font-weight: bold;
  color: #18457d;
  margin: 0 auto;
  width: 100%;
  background-color: #f9f7f7;
}

.expert {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.expert h4 {
  font-size: 46px;
  font-weight: bold;
}

.expert h5 {
  font-size: 37px;
  font-weight: 500;
  border: 3px solid #14427d;
  padding: 12px 30px;
  border-radius: 35px;
}

.expert h5:hover {
  color: white;
  background-color: #14427d;
}

.appointmentImage {
  height: 135px;
}

.onlineConsult {
  text-align: -webkit-right;
}

.modalContent {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 2% auto;
  height: 96%;
  border-radius: 35px;
}
.modal_card {
  width: 100%;
  text-align: center;
  overflow-y: scroll;
  max-height: 100%;
  border: 4px solid #000000;
  border-radius: 25px;
}
.card.modal_card:focus-visible {
  outline: none !important;
}
.datePicker {
  border-radius: 3px !important;
  width: 51% !important;
  border: 1px solid #ced4da;
  line-height: 2;
}
.pay_service_select {
  margin-bottom: 20px;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.document_content {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  width: 50%;
  margin: 5% auto;
  height: auto;
  border-radius: 35px;
}

.doctorText {
  font-size: 20px;
}

.option {
  text-align: left;
}

.datecheck {
  background-color: #f5f1f1;
  padding-left: 10px;
}

.meeting_content {
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 10px 20px;
  width: 99%;
  margin: 20px 5px;
  flex-direction: column;
  border-radius: 20px;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  margin: 0 18%;
  font-size: larger;
}

.filter span {
  padding: 0 10px;
}

.filter span :hover {
  cursor: pointer;
}

.uploadDocument {
  display: flex;
}

.btn :hover {
  color: white;
}

textarea {
  resize: none;
}

.dropzone {
  cursor: pointer;
}
.dropcontainer {
  display: flex;
  justify-content: center;
}

ul {
  list-style-type: none;
}

.serach {
  width: 62%;
  margin: 0 auto;
}

.updatemodal {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.updatemodal .form-label {
  color: var(--text-black);
  text-align: right;
  font-family: var(--assistant);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.updatemodal .form-label.id-label {
  display: flex;
  justify-content: center;
  gap: 15px;
} 

.react-date-picker__wrapper {
  border: none !important;
}

.document {
  width: 89%;
}

.update_details {
  width: 142%;
  margin-top: 28px;
}

.text {
  font-size: 18px;
}

.document1 {
  width: 43%;
}

.yes_no {
  display: flex;
  justify-content: space-around;
}

.content_div {
  margin: 10% 20%;
}

.appointment_div {
  display: flex;
  justify-content: space-around;
}

.appointment_status {
  position: relative;
  width: 235px;
  text-align: center;
  padding: 32px;
  border-radius: 26px;
  background-color: #d9d9d9;
}

.img_div {
  border: 3px solid #ec411b;
  border-radius: 999px;
  height: 50px;
  width: 50px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.user_img {
  width: 22px;
}

.info_div {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.row_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.prof {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
}

.line_img {
  width: 34px;
  height: 0px;
  border: 1px solid #000000;
  transform: rotate(90deg);
}

.line_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column_div {
  display: flex;
  flex-direction: column;
}

.button_div button {
  box-sizing: border-box;
  width: 89px;
  height: 40px;
  background: #cccccc;
  border: 1px solid #000000;
  border-radius: 23px;
}

.button_div {
  text-align: center;
}

.tr_div {
  box-sizing: border-box;
  width: 927px;
  height: 90px;
  background: #f1f1f1;
  border: 1px solid #000000;
}

.scheduleDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 355px;
  padding: 0 20px 0 20px;
}

.name1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding: 0 6px;
}

.bell_Icon {
  position: absolute;
  z-index: 1;
  right: 7px;
  top: 5px;
}

.td_div {
  vertical-align: middle;
}

.updateButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.updateButton button {
  border: none;
  background-color: #fff;
  color: #14427d;
  border-radius: 10px;
  padding: 0 9px;
  margin: 2px;
}

.updateButton button:hover {
  background-color: #14427d !important;
  color: #ffff !important;
}

.surveyDiv {
  background-color: rgb(165 162 162 / 35%);
  border-radius: 15px;
  padding: 10px;
}

.name1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: gray;
}

.profileDiv {
  display: flex;
  align-items: center;
}

.doctorDetails {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.profile_img {
  width: 96px;
}

.doctor_name1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 15px;
  color: #14427d;
}

.consult {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #14427d;
}

.dateDiv {
  vertical-align: middle;
}

.datetimes {
  padding: 0 2px;
}

.doctor_line_img {
  width: 80px;
  height: 0px;
  border: 1px solid gray;
  transform: rotate(90deg);
}

.buttonInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 32px;
}

.buttonInfo button {
  padding: 9px 52px;
  border-radius: 10px;
  font-size: x-large;
  margin-bottom: 7px;
  font-family: almoni-light, sans-serif !important;
}

.buttonInfo h3 {
  text-transform: uppercase;
  font-size: 22px;
  color: #1E0F3C;
  font-weight: 700;
  margin-bottom: 5px;
}

.ulDiv {
  vertical-align: middle;
}

.payButton {
  border-radius: 20px;
  padding: 25px 10px 10px 25px;
  width: 29%;
}

.priceInput {
  margin-bottom: 20px;
}
.idf-meeting-date {
  padding-top: 5px;
  margin: 0 20%;
}
.idf-meeting-date h4 {
  margin: 0;
  font-size: 16px;
  text-align: right;
}

.fileList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20%;
  padding: 10px;
  border-bottom: 1px solid #000;
}
.doc-with-sumary .fileList {
  margin: 0 10%;
}
.file-thumbnail img {
  width: 50px;
}
p.file-name {
  font-size: 16px;
  color: var(--text-primary);
  font-weight: 600;
  font-family: var(--assistant) !important;
  margin: 0;
  text-align: right;
}
.file-btn svg {
  cursor: pointer;
}
.fileList li {
  font-size: 22px;
  color: #14427d;
  font-weight: bold;
  font-family: "Heebo" !important;
  list-style: none;
}
.card_content {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: scroll;
}
.viewList {
  display: flex;
  justify-content: space-around;
}
.viewList li {
  font-size: 22px;
  color: #14427d;
  font-weight: bold;
}
/******** new css ***********/
.user-mangement-page {
 padding-top: 40px;
  margin-bottom: 80px;
  padding-left: 10px;
    padding-right: 10px;
}
.user-mangement-page .ag-cell {
  display: flex;
  align-items: center;
}
.user-mangement-page .ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.user-mangement-page .ag-theme-alpine .ag-ltr .ag-cell {
  text-align: left !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user-mangement-page span.ag-header-cell-text, .adminDataTable .ag-header-cell-text {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: var(--assistant);
}
.user-mangement-page .ag-cell-value, .adminDataTable .ag-cell-value {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: var(--assistant);
  color: var(--text-primary);
}
.user-mangement-page .ag-theme-alpine .ag-header-cell,
.user-mangement-page .ag-theme-alpine .ag-header-group-cell,
.user-mangement-page .ag-theme-alpine .ag-cell {
  padding: 0px 6px !important;
}
.user-mangement-page .form-control.status-text {
  font-size: 16px !important;
  font-weight: 500;
  position: relative;
}
.user-mangement-page .ag-cell-wrapper {
  white-space: nowrap;
  overflow: hidden;
}
.user-mangement-page .ag-header-container {
  margin: 0px auto;
  background: #f1f1f1;
}
.user-mangement-page .ag-header {
  background-color: transparent;
  border-bottom: transparent;
}
.user-mangement-page .ag-center-cols-container {
  margin: 0px auto;
}
.user-mangement-page .ag-body-viewport {
  overflow-x: hidden !important;
}
.user-mangement-page .ag-body-horizontal-scroll-viewport::-webkit-scrollbar, .user-mangement-page .ag-body-viewport::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.user-mangement-page .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb, .user-mangement-page .ag-body-viewport::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #1E0F3C;
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.admin-prescription-only {
  background: #EEF1FF!important;
}
.meetingLogContent {
  direction: ltr;
}
.close-btn-grid {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.close-btn-grid button {
  max-width: 30px;
  width: 100%;
  height: 30px;
  border: none;
  background: none;
}
.close-btn-grid button img {
  width: 18px;
  height: 18px;
}
.table-header-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 10px;
  gap: 11px;
}
.table-header-section .download_report {
  margin: 0px;
}
.global-search {
  margin: 0px;
  max-width: 210px;
  width: 100%;
  padding: 6px 10px;
  text-align: center;
  height: 38px;
}
.global-search[type=search] {
  outline-offset: 0px;
}
.table-header-section .download-btn {
  padding: 0px 15px;
}
.table-header-section .download-btn button {
  width: 100%;
}
.table-header-section .dropdown-menu .form-check {
  margin: 0;
  padding: 0;
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.table-header-section .dropdown-menu .form-check-input {
  margin: 0;
  width: 16px;
  height: 16px;
}
.table-header-section .dropdown-menu .label{
  font-size: 16px;
  color: #18457d;
  font-weight: 600;
}
.table-header-section .dropdown-toggle::after {
  margin-right: 5px;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.accrdion-list .accordion-button {
  color: var(--text-primary);
  -webkit-column-gap: 50px;
  column-gap: 50px;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  padding: 15px 40px;
  text-align: inherit;
  direction: ltr;
  font-family: var(--assistant);
}

.accrdion-list .accordion-button:focus {
  box-shadow: none;
}
.doc-with-sumary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  position: relative;
}
.hidoc-dyte-container {
  height: 100vh;
}
.box-style .hidoc-dyte-container {
  height: calc(98% - 36px);
}
.loading-design {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.doc-view-iframe {
  width: 100%;
  height: 100%;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 2px solid black;
  text-align: center;
}
.box-style {
  border: 2px solid black;
  padding: 1%;
}
.doc-with-right .box-style {
  padding: 0;
}

.doc-with-left,.doc-with-right, .doc-with-mid {
  width: 48%;
}

.video-sec  .doc-with-left, .video-sec .doc-with-right, .video-sec  .doc-with-mid {
  width: 32%;
}
.start-meeting-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  padding-top: 40px;
}

.auto-complete {
  position: absolute;
  width: 100%;
  max-height: 200px;
  height: auto;
  top: 75px;
  background: #fff;
  left: 0;
  border-radius: 5px;
  z-index: 9999;
  border: 1px solid #eaebec;
  overflow: auto;
  padding: 5px 0px;
}

.auto-complete li {
  padding: 8px;
  margin: 5px;
  line-height: 1.2;
  color: var(--text-black);
  cursor: pointer;
}

.medicine-select .form-control {
  width: 100%;
  height: 43px;
  border-radius: 5px;
  font-weight: 400;
  padding: 12px 12px 12px 30px;
  text-align: right;
  text-transform: uppercase;
  color: var(--text-black);
  font-size: 16px;
}
.medicine-select .form-control::placeholder {
  color: var(--text-black);
  text-transform: unset;
}
.form-type {
  display: inline-block;
  width: 100%;
  border-radius: 3px !important;
  border: 1px solid #ced4da;
  padding: 2px 10px !important;
}
.form-type:focus-visible {
  outline-color: #18457d !important;
}
.zoom-meeting button {
  background-color: #14427d;
  color: #fff;
}
.meetingSDKElement {
  width: 100%;
}
.meetingSDKElement p {
  color: #fff !important;
  font-weight: 600;
  font-size: 20px;
}

.doc-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.doc-count {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 100%;
  align-items: center;
}
.doc-count h6 {
  font-size: 20px;
  font-weight: 600;
}
iframe.document-iframe {
  width: 100% ;
  height: calc(100% - 70px);
}
.document-iframe-grid {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.doc-grid img {
  margin: auto;
}
iframe.document-iframe img  {
  margin: auto;
}
.doctor-user.userWrapper .custom-date-range {
  padding: 0% 2%;
  border: 2px solid #000;
  border-radius: 30px;
  height: 40px;
  width: 40%;
  font-size: 16px;
}
.doctor-user.userWrapper .react-datepicker {
  direction: rtl !important;
}
.doctor-user .adminDataTable {
  padding: 0;
  background: transparent;
}
.custom-date-picker {
  position: relative;
  margin-bottom: 2%;
}
.custom-date-picker .react-datepicker__tab-loop {
  position: absolute;
  right: 0px;
  top: 60px;
}
.custom-date-picker .react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0;
  transform: unset !important;
  inset: unset !important;
}
.custom-date-picker .react-datepicker__close-icon {
  padding: 1%;
  padding-left: 0;
}
.custom-date-picker .react-datepicker__close-icon::after {
  content: "" !important;
  background: url("../../assets//images//cross/crs.png") no-repeat;
  background-color: transparent !important;
  background-size: contain;
}
.confirmation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.confirmation-modal h2 {
  margin: 0;
  font-weight: 600;
  padding-bottom: 20px;
  font-family: var(--assistant);
  color: var(--text-primary);
}
.confirmation-modal .yes_no {
  width: 100%;
}

.zoom-controls {
  position: absolute;
  right: 14px;
  top: 14px;
  display: flex;
  gap: 10px;
}
.doc-with-mid .zoom-controls {
  position: unset;
}
.doc-with-mid .zoom-controls .btn-primary {
  font-size: 14px;
  padding: 5px;
  line-height: 1;
  height: 30px;
}
.admin-upload-loader {
  padding-top: 20px;
}
.password-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  user-select: text;
}
.copy-btn {
  margin-left: 15px;
  background: #f5f6f8;
  color: #18457d;
  border: none;
  font-size: 20px;
  font-weight: 600;
}
.copy-btn i {
  font-size: 20px;
}
.idf-upload-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 650px;
  width: 100%;
}
.idf-upload-form .form-group {
  width: 48%;
  text-align: left;
}

.idf-upload-form .form-label {
  color: var(--text-black);
  font-family: var(--assistant);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.idf-upload-form  .form-control {
  border-radius: 4px;
  border: 2px solid #9891A5;
  background: #FFF;
  color: #1E0F3C;
  font-family: var(--assistant);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important;
  padding: 0px 40px !important;
  height: 48px;
}
.idf-upload-form .form-control[type=file] {
  padding: .375rem .75rem !important;
}
.idf-upload-form.rtl-css .form-group {
  text-align: right;
}
.idf-upload-form .search-input {
  max-width: 100%;
}
.submit-btn-grid {
  width: 100%;
}
.meeting-type-toggle {
  display: flex;
  flex-direction: column;
}

.idf-upload-form input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  background-color: rgba(233, 233, 234, 1);
  width: 50px;
  height: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.7s ease;
  cursor: pointer;
}

.idf-upload-form input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 20px;
  transform: scale(0.8);
  transition: all 0.7s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.idf-upload-form input[type="checkbox"]:checked::before {
  background-color: #fff;
  left: 18px;
}

.idf-upload-form input[type="checkbox"]:checked {
  background-color: var(--foundation-violet);
}
.idf-upload-form .form-label.label-value {
  font-weight: 600;
  margin-top: 8px;
}
.idf-upload-form  .search-result-section {
  top: 85px;
  right: unset;
}
.idf-upload-form .form-control:focus {
  border-width: 2px;
  border-image: linear-gradient(360deg, #FF0F7B 8.23%, #F89B29 94.91%);
  border-image-slice: 1;
  border-style: solid;
}
.error-body {
  height: 350px;
  overflow: auto;
  padding: 5px 15px;
}
.error-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  background-color: #aaa;
}
.error-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #1E0F3C;
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.response-modal .card-body {
  max-width: 75%;
  width: 100%;
}

.error-subgrid{
  display: flex;
  margin-top: 10px;
  text-align: center; 
}
.error-grid{
  margin-top: 35px;
}
.error-head{
  font-weight: 700;
  color: red;
}
.success-grid h4{
  font-weight: 700;
}
.response-grid{
  margin-bottom: 35px;
}

.contact-form-content form input[type="number"] {
  text-align:center;
}
.med-info-content .card-body {
  max-width: 650px;
  width: 100%;
  padding: 40px 20px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}

.med-info-content .form-select {
  border-radius: 4px;
  border: 2px solid #9891A5;
  background: #FFF;
  box-shadow: none !important;
  color: #000;
  font-family: var(--assistant);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important;
  outline: 0 !important;
  padding: 10px 16px !important;
  width: 100%;
}
.med-info-content .checkleft label, .med-info-content .checkright label {
  color: var(--text-black);
  font-family: var(--assistant);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.med-info-content label a {
  color: var(--text-black);
  font-family: var(--assistant);
  text-decoration: underline !important;
}
.med-info-content .form-check-input[type="checkbox"]  {
  cursor: pointer;
  box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #000;
  width: 24px;
  height: 24px;
  border-radius: 0;
  background-color: transparent;
}
.med-info-content  .form-check-input:checked[type="checkbox"] {
  background-color: #000;
}
.med-info-content .checkright label {
  color: var(--text-black);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.med-info-content .black-btn, .med-info-content .border-btn  {
  max-width: 200px;
  margin: 0px auto !important;
}
.med-info-content h6 {
  color: #000;
  font-family: var(--assistant) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  text-align: center;
  padding-bottom: 20px;
}

.add-participant form {
  max-width: 50%;
  width: 100%;
  margin: 0px auto;
}
.add-participant h1 {
  margin: 0px;
  padding-bottom: 30px;
  padding-top: 10px;
}
.add-participant h6.guest-number-heb {
  max-width: 300px;
  margin: 0px auto;
}
.add-participant h6.guest-number-eng {
  max-width: 385px;
  margin: 0px auto;
}
.add-participant h6.guest-number-heb span {
  display: inline-block;
  direction: ltr;
}
.meeting-link-text {
  padding-top: 10px;
}
.meeting-link-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 2rem;
}

.disabled-checkbox-cell .ag-checkbox-input-wrapper {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events: none;
}
:dir(rtl) {
  .Mui-error {
    text-align: right !important;
  }
}

@media (max-width: 1024px) {
  .modalContent {
    margin: 0 auto;
    height: auto !important;
  }
}

@media (max-width: 991px) {
  .modalContent {
    width: 90%;
  }
  .alllist {
    display: flex !important;
  }
  .fileList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .fileList li {
    font-size: 16px;
    color: #14427d;
    font-weight: bold;
  }
  .doc-with-left,.doc-with-right {
    width: 100%;
  }
  .box-style {
    width: 100% !important;
  }
  .accrdion-list .ag-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
  .accrdion-list .ag-selection-checkbox {
    margin: 0 !important;
  }
}

@media (max-width: 767px) {
  .textchange h3 {
    font-size: 16px;
  }

  .summaryModal {
    width: 80%;
  }
  .document_content {
    width: 80%;
  }
  .fileList {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .fileList li {
    font-size: 16px;
    color: #14427d;
    font-weight: bold;
  }
  .user-mangement-page {
    margin-bottom: 120px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .table-header-section {
    padding-top: 0;
  }
  .table-header-section .btn-primary {
    font-size: 14px;
  }
  .global-search {
    max-width: 230px;
  }
  .doctor-user.userWrapper .custom-date-range {
    width: 100%;
  }
  .custom-date-picker {
    margin-bottom: 5%;
  }
  .custom-date-picker .react-datepicker__close-icon {
    padding: 4%;
    padding-left: 0;
  }
  .pay_service_select {
    width: 85%;
  }
  .response-modal .card-body {
    max-width: 100%;
    padding: 0px 10px;
  }
  .error-body {
    height: 300px;
    padding: 5px;
  }
  .response-grid {
    margin-bottom: 10px;
  }
  .success-grid h4 {
    font-size: 20px;
  }
  .add-participant form {
    max-width: 100%;
  }
  .add-participant h1 {
    padding-top: 0px;
    padding-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .document_content {
    width: 80% !important;
    height: 350px;
  }
  .textchange h3 {
    font-size: 16px;
  }

  .summaryModal {
    width: 80%;
  }
  .fileList {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .fileList li {
    font-size: 13px;
    color: #14427d;
    font-weight: bold;
  }
}
